import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { AddAwsAccountMutation, UpdateAwsAccountInput } from "@m/api/v4/types";
import { toast } from "@m/ui";

export const ADD_AWS_ACCOUNT = gql(/* GraphQL */ `
  mutation AddAwsAccount($input: UpdateAwsAccountInput!) {
    updateAwsAccount(input: $input) {
      ok
      message
    }
  }
`);

export const useAddAwsAccount = () => {
  const handleError = () => toast.error("Unable to add AWS account");

  const [mutate, result] = useMutation(ADD_AWS_ACCOUNT, {
    onCompleted: (data: AddAwsAccountMutation) => {
      if (!data.updateAwsAccount.ok) return handleError();
      toast.success("AWS account added");
    },
    onError: handleError,
  });

  const addAwsAccount = (input: UpdateAwsAccountInput) => {
    return mutate({ variables: { input } });
  };

  const awsAccountAdded = result.data?.updateAwsAccount.ok;

  return [addAwsAccount, { data: awsAccountAdded, ...result }] as const;
};
