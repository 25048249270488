import clsx from "clsx";
import { ReactNode } from "react";
import Overflow from "react-overflow-indicator";
import { Link } from "react-router-dom";

import { MissionLogo } from "@m/ui";

import { PATHS } from "@atlas/constants";

import { UserDropdown } from "../UserDropdown";

import { SidebarLink } from "./SidebarLink";

export const AtlasSidebar = () => (
  <SidebarWrapper>
    <SidebarLink title="Customers" to={PATHS.CUSTOMERS} />
    <SidebarLink title="AWS Accounts" to={PATHS.AWS_ACCOUNTS} />
  </SidebarWrapper>
);

const SidebarWrapper = ({ children }: { children: ReactNode }) => (
  <nav
    data-testid="sidebar"
    className={clsx(
      "flex h-screen flex-col",
      "border-r border-neutral-100 bg-neutral-50",
      "static block w-[240px] flex-shrink-0 flex-grow-0"
    )}
  >
    <Link to={PATHS.ROOT} className="flex items-center gap-1 p-2">
      <MissionLogo
        logo="mark"
        style={{ height: 20, width: 20 }}
        className="hover:opacity-80"
      />
      <div className="text-sm font-bold">Mission Atlas</div>
    </Link>

    <Overflow className="relative flex grow flex-col overflow-y-auto">
      <Overflow.Content>
        <menu className="flex flex-col px-1">{children}</menu>
      </Overflow.Content>
      <Overflow.Indicator direction="up">
        <Gradient toDirection="bottom" />
      </Overflow.Indicator>
      <Overflow.Indicator direction="down">
        <Gradient toDirection="top" />
      </Overflow.Indicator>
    </Overflow>

    <div className="flex items-center p-2">
      <UserDropdown />
    </div>
  </nav>
);

const Gradient = ({ toDirection }: { toDirection: "bottom" | "top" }) => (
  <span
    className={clsx(
      "pointer-events-none absolute left-0 right-0 h-5 from-neutral-50",
      {
        "top-0 border-t bg-gradient-to-b": toDirection === "bottom",
        "bottom-0 border-b bg-gradient-to-t": toDirection === "top",
      }
    )}
  />
);
