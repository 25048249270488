import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { gql } from "@m/api/v4";
import { MeterMarketplaceProductUsageInput } from "@m/api/v4/types";

export const CREATE_METERED_CHARGE = gql(/* GraphQL */ `
  mutation meterMarketplaceProductUsage(
    $input: MeterMarketplaceProductUsageInput!
  ) {
    meterMarketplaceProductUsage(input: $input) {
      ok
      message
    }
  }
`);

export const useCreateMeteredCharge = () => {
  const [mutate, { loading }] = useMutation(CREATE_METERED_CHARGE, {
    onError: () =>
      toast.error(
        "There was a problem creating a manual charge. The Customer was not billed."
      ),
  });

  const addMeteredCharge = (data: MeterMarketplaceProductUsageInput) => {
    mutate({
      variables: { input: data },
      refetchQueries: ["GetMarketplaceMeteredCharges"],
    });
  };

  return [addMeteredCharge, { loading }] as const;
};
