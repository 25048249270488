import { SidebarLink } from "@atlas/components/AtlasSidebar";

export const Sidebar = () => {
  return (
    <nav data-testid="sidebar" className="mr-3 min-w-[160px]">
      <menu>
        <SidebarLink title="Overview" to="./overview" />
        <SidebarLink title="Subscriptions" to="./subscriptions" />
      </menu>
    </nav>
  );
};
