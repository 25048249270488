import {
  Badge,
  Button,
  CopyButton,
  Field,
  Modal,
  Tooltip,
} from "@m/ui/components";
import { toProperCase } from "@m/utils";

import { AwsAccount } from "../api";

import { AwsAccountSyncStatus } from "./AwsAccountSyncStatus";

interface AwsAccountDetailsModalProps {
  onClose: () => void;
  open?: boolean;
  awsAccount: AwsAccount;
}

export const AwsAccountDetailsModal = ({
  onClose,
  open = true,
  awsAccount,
}: AwsAccountDetailsModalProps) => {
  const {
    disabled,
    email,
    externalId,
    isSyncing,
    lastCompletedSynced,
    supportPlan,
    syncDisableReason,
    syncsEnabled,
  } = awsAccount || {};

  return (
    <Modal
      open={open}
      onClose={onClose}
      header={<Modal.Title>Account Details</Modal.Title>}
    >
      <Modal.Description className="my-3 flex flex-col gap-2">
        <Field
          label="Mission API Account Status"
          labelId="mission-api-account-status"
          row
        >
          <div className="flex justify-end">
            <Badge
              aria-labelledby="mission-api-account-status"
              label={disabled ? "Disabled" : "Active"}
              status={disabled ? "negative" : "positive"}
            />
          </div>
        </Field>
        <Field
          label="Mission API Account Sync Status"
          labelId="mission-api-account-sync-status"
          row
        >
          <div className="flex justify-end">
            <AwsAccountSyncStatus
              aria-labelledby="mission-api-account-sync-status"
              isSyncing={isSyncing}
              lastCompletedSynced={lastCompletedSynced}
              syncDisableReason={syncDisableReason}
              syncsEnabled={syncsEnabled}
            />
          </div>
        </Field>
        {externalId && (
          <Field label="External ID" labelId="external-id">
            <div aria-labelledby="external-id" className="flex items-center">
              <Tooltip
                asChild
                className="max-w-xs break-all"
                content={externalId}
              >
                <div className="truncate underline decoration-dashed underline-offset-2">
                  {externalId}
                </div>
              </Tooltip>
              <CopyButton text={externalId} />
            </div>
          </Field>
        )}
        <Field label="AWS Support Plan" labelId="aws-support-plan">
          <div aria-labelledby="aws-support-plan">
            {toProperCase(supportPlan)}
          </div>
        </Field>
        <Field label="Root Email Address" labelId="root-email-address">
          <div aria-labelledby="root-email-address">{email}</div>
        </Field>
      </Modal.Description>
      <Modal.Actions>
        <Button kind="primary" fill="subdued" onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
