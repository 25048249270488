import { ReactNode } from "react";

interface PageTitleProps {
  title: ReactNode;
  actions?: ReactNode;
}

export const PageTitle = ({ title, actions }: PageTitleProps) => (
  <div className="flex justify-between">
    <div className="grow text-xl font-bold">{title}</div>
    {actions}
  </div>
);
