import clsx from "clsx";

import { Spinner, Tooltip } from "@m/ui";
import { formatDate, fromSnakeCaseToProperCase } from "@m/utils";

interface AwsAccountSyncStatusProps {
  awsAccountOnboardingStatus?: string;
  isSyncing: boolean;
  lastCompletedSynced: string;
  syncDisableReason: string;
  syncsEnabled: boolean;
}

export const AwsAccountSyncStatus = ({
  awsAccountOnboardingStatus = null,
  isSyncing,
  lastCompletedSynced,
  syncDisableReason,
  syncsEnabled,
  ...props
}: AwsAccountSyncStatusProps) => {
  const isOnboarded = [null, "COMPLETED"].includes(awsAccountOnboardingStatus);
  const isConnected = isOnboarded && syncsEnabled && !isSyncing;
  const isWarning = isOnboarded && !syncsEnabled && !isSyncing;
  const notConnected = !isOnboarded;

  let syncMessage: string;
  if (isWarning) syncMessage = fromSnakeCaseToProperCase(syncDisableReason);
  else if (lastCompletedSynced)
    syncMessage = `Last synced ${formatDate(lastCompletedSynced)}`;

  const tooltipDisabled = !syncMessage || isSyncing;

  return (
    <div
      className="flex items-center gap-[6px] text-sm font-semibold"
      {...props}
    >
      {isSyncing && <Spinner className="text-accent" size={1.5} />}
      {isConnected && (
        <DotIcon className="fill-status-good" height="6px" width="6px" />
      )}
      {isWarning && (
        <DotIcon className="fill-status-warning" height="6px" width="6px" />
      )}
      {notConnected && (
        <DotIcon className="fill-status-neutral" height="6px" width="6px" />
      )}

      <Tooltip content={syncMessage} disabled={tooltipDisabled} side="bottom">
        <div
          className={clsx({
            "cursor-help border-b border-dashed": !tooltipDisabled,

            // text color
            "text-accent": isSyncing || notConnected,
            "border-status-good text-status-good": isConnected,
            "border-status-warning text-status-warning": isWarning,
          })}
        >
          {isSyncing && "Syncing"}
          {isConnected && "Connected"}
          {isWarning && "Sync issue"}
          {notConnected && "Not Connected"}
        </div>
      </Tooltip>
    </div>
  );
};

const DotIcon = ({ className, height = "10", width = "10", ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="dot-icon"
    {...props}
  >
    <circle cx="5" cy="5" r="5" className={className} />
  </svg>
);
