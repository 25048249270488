import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";

import { Button, Field, Fields, Input, Modal, Select } from "@m/ui";

import { useAddAwsAccount, useCompanies } from "../api";

interface AddAwsAccountModalProps {
  open?: boolean;
  onClose: () => void;
}

export const AddAwsAccountModal = ({
  open = true,
  onClose,
}: AddAwsAccountModalProps) => {
  const {
    data: { companies },
    loading: companiesLoading,
  } = useCompanies();

  const [
    addAwsAccount,
    { data: awsAccountAdded, loading: addAwsAccountLoading, reset },
  ] = useAddAwsAccount();

  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [accountId, setAccountId] = useState<string>("");
  const [accountName, setAccountName] = useState<string>("");

  const handleClose = useCallback(() => {
    onClose();
    reset();
    setSelectedCompany("");
    setAccountId("");
    setAccountName("");
  }, [onClose, reset]);

  const handleSubmitAddAccount = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addAwsAccount({
      companyId: selectedCompany,
      id: accountId,
      name: accountName,
    });
  };

  useEffect(() => {
    if (awsAccountAdded) handleClose();
  }, [awsAccountAdded, handleClose]);

  const companyOptions = useMemo(
    () =>
      companies.map((company) => (
        <option key={company.id} value={company.id}>
          {company.name}
        </option>
      )),
    [companies]
  );

  return (
    <Modal
      header={<Modal.Title>Add Account</Modal.Title>}
      open={open}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmitAddAccount}>
        <Fields className="my-2.5">
          <Field label="Company Name" htmlFor="company-name">
            <Select
              disabled={companiesLoading}
              id="company-name"
              onChange={(e) => setSelectedCompany(e.target.value)}
              options={companyOptions}
              placeholder="Select Company"
              required
              value={selectedCompany}
            />
          </Field>
          <Field label="Account ID" htmlFor="account-id">
            <Input
              id="account-id"
              maxLength={12}
              minLength={12}
              onChange={(e) => setAccountId(e.target.value)}
              pattern="^\d{12}$"
              placeholder="e.g. 123456789245"
              required
              title="Enter a valid AWS account ID."
              type="text"
              value={accountId}
            />
          </Field>
          <Field label="Account Name" htmlFor="account-name" flag="optional">
            <Input
              id="account-name"
              placeholder="e.g. ABC Accs"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
            />
          </Field>
        </Fields>

        <Modal.Actions className="flex justify-between">
          <Button fill="none" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            kind="primary"
            type="submit"
            loading={addAwsAccountLoading}
            disabled={!selectedCompany || !accountId}
          >
            Add Account
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};
