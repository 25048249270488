import { Float } from "@headlessui-float/react";
import { Listbox } from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import PT from "prop-types";
import { Fragment } from "react";

import { Badge, Checkbox } from "@m/ui";

const ClearFilterIcon = ({ ariaLabel, className, onClick, value }) => {
  const hasValue = Array.isArray(value) ? value.length > 0 : value;

  if (!hasValue) return null;

  return (
    <XMarkIcon
      aria-label={`Deselect ${ariaLabel}`}
      onClick={onClick}
      className={className}
    />
  );
};

export const BaseFilter = ({
  ariaLabel = "BaseFilter",
  className = "",
  direction = "bottom-start",
  disabled = false,
  icon,
  initialValue,
  multiple = false,
  onClear,
  onChange,
  options = [],
  buttonClassName,
  optionsClassName,
  selection,
  displayValue = "",
}) => {
  const handleClear = (e, open) => {
    onClear();

    if (!open) e.stopPropagation();
  };

  return (
    <Listbox
      className={className}
      disabled={disabled}
      multiple={multiple}
      onChange={onChange}
      value={selection}
    >
      <Float
        as="div"
        className="relative"
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        floatingAs={Fragment}
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        offset={4}
        placement={direction}
      >
        <Listbox.Button
          className={clsx(
            "select-none whitespace-nowrap rounded-full border px-2 py-1 text-sm font-semibold leading-none transition-colors duration-100 focus:outline-none",
            {
              "text-default": !displayValue,
              "!border-layout-active bg-blue-50 !text-action":
                (displayValue !== selection && !multiple) ||
                (multiple && selection.length > 0),
              "hover:border-layout-active focus-visible:border-layout-active":
                !disabled,
              "cursor-not-allowed opacity-60": disabled && !displayValue,
            },
            buttonClassName
          )}
          aria-label={ariaLabel}
          aria-pressed={displayValue}
        >
          {({ value, open }) => (
            <>
              {icon && <span>{icon}</span>}
              <span className="inline-flex items-center gap-0.5 whitespace-nowrap">
                {displayValue || initialValue}
                {multiple && selection.length > 0 && (
                  <Badge
                    aria-label="Selected Filters Count"
                    status="active"
                    strong={true}
                    label={selection.length}
                    size="small"
                  />
                )}
                <ChevronDownIcon className="inline h-2 w-2" />
                <ClearFilterIcon
                  onClick={(e) => handleClear(e, open)}
                  value={displayValue === initialValue ? null : value}
                  ariaLabel={ariaLabel}
                  className="inline h-2 w-2"
                />
              </span>
            </>
          )}
        </Listbox.Button>

        <Listbox.Options
          aria-label={`${ariaLabel} Options`}
          static={true}
          className={clsx(
            "max-h-[250px] overflow-auto rounded-md border bg-layout-background p-1 outline-none drop-shadow focus:outline-none",
            optionsClassName
          )}
        >
          {options.map((option) => {
            const { id, label } = option;
            return (
              <Listbox.Option
                aria-label={`Option ${id}`}
                key={id}
                value={id}
                as={Fragment}
              >
                {({ selected, active }) => (
                  <button
                    className={clsx(
                      "flex w-full cursor-pointer flex-row items-center rounded-md p-1 text-sm font-semibold text-default focus:outline-none focus-visible:outline-none",
                      {
                        "bg-neutral-200 bg-opacity-[.6]": active,
                        "!bg-[#EDF3F8]": selected && !multiple,
                      }
                    )}
                  >
                    {multiple && (
                      <Checkbox className="mr-1.5" checked={selected} />
                    )}
                    <div className="whitespace-nowrap">{label}</div>
                  </button>
                )}
              </Listbox.Option>
            );
          })}
        </Listbox.Options>
      </Float>
    </Listbox>
  );
};

BaseFilter.propTypes = {
  ariaLabel: PT.string,
  className: PT.string,
  direction: PT.oneOf([
    "top",
    "top-start",
    "top-end",
    "right",
    "right-start",
    "right-end",
    "bottom",
    "bottom-start",
    "bottom-end",
    "left",
    "left-start",
    "left-end",
  ]),
  disabled: PT.bool,
  icon: PT.node,
  initialValue: PT.string.isRequired,
  multiple: PT.bool,
  onChange: PT.func.isRequired,
  onClear: PT.func.isRequired,
  options: PT.arrayOf(
    PT.shape({
      id: PT.oneOfType([PT.string, PT.number]).isRequired,
      label: PT.node.isRequired,
    })
  ),
  optionsClassName: PT.string,
  buttonClassName: PT.string,
  selection: PT.oneOfType([PT.string, PT.number, PT.array]).isRequired,
  displayValue: PT.string,
};
