import { Navigate, Outlet } from "react-router-dom";

import { Logout } from "@m/login";

import { PATHS } from "@atlas/constants";
import { DefaultAtlasLayout } from "@atlas/layouts/DefaultAtlasLayout/DefaultAtlasLayout";

import { defaultAtlasRoutes } from "./defaultAtlasRoutes";

export const authenticatedAtlasRoutes = [
  {
    index: true,
    element: <Navigate to={PATHS.CUSTOMERS} replace />,
  },
  {
    path: "*",
    element: (
      <DefaultAtlasLayout>
        <Outlet />
      </DefaultAtlasLayout>
    ),
    children: defaultAtlasRoutes,
  },
  {
    path: "logout",
    element: <Logout />,
  },
];
