import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";
import {
  AggregateBillingPeriod,
  GetAggregateMeteredChargesMonthlyQuery,
} from "@m/api/v4/types";
import { toast } from "@m/ui";

export const GET_AGGREGATE_METERED_CHARGES_MONTHLY = gql(/* GraphQL */ `
  query GetAggregateMeteredChargesMonthly(
    $marketplaceRegistrationSignupTokens: [ID!]
    $billingPeriodType: AggregateBillingPeriod!
    $dimensionTypeIds: [String]
  ) {
    aggregateMarketplaceMeteringTransactions(
      marketplaceRegistrationSignupTokens: $marketplaceRegistrationSignupTokens
      dimensionTypeIds: $dimensionTypeIds
      billingPeriodType: $billingPeriodType
    ) {
      edges {
        node {
          id
          billingPeriodType
          dimensionTypeId
          meteredQuantity
          hour
          day
          month
          year
        }
      }
      totalCount
    }
  }
`);

interface MonthlyMeteredChargesParams {
  dimensions: string[];
}

export type AggregateMeteredChargeMonthly =
  GetAggregateMeteredChargesMonthlyQuery["aggregateMarketplaceMeteringTransactions"]["edges"][number]["node"];

export const useAggregateMeteredChargesMonthly = (
  signupToken: string,
  params: MonthlyMeteredChargesParams,
  onCompleted: (data: GetAggregateMeteredChargesMonthlyQuery) => void
) => {
  const onError = () =>
    toast.error("There was an error loading monthly metered charges");

  const { data, ...result } = useQuery(GET_AGGREGATE_METERED_CHARGES_MONTHLY, {
    variables: {
      billingPeriodType: AggregateBillingPeriod.Monthly,
      marketplaceRegistrationSignupTokens: [signupToken],
      dimensionTypeIds: params.dimensions,
    },
    onError,
    onCompleted,
  });
  const edges = data?.aggregateMarketplaceMeteringTransactions?.edges ?? [];
  const totalCount =
    data?.aggregateMarketplaceMeteringTransactions?.totalCount ?? 0;

  const aggregatedCharges =
    edges
      .map((edge) => edge.node)
      .filter((node): node is AggregateMeteredChargeMonthly => node !== null) ??
    [];

  return { data: { aggregatedCharges, totalCount }, ...result };
};
