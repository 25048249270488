import clsx from "clsx";
import { NavLink, NavLinkProps } from "react-router-dom";

interface SidebarLinkProps extends NavLinkProps {
  className?: string;
  title: string;
  to: string;
}

export const SidebarLink = ({
  className,
  title,
  to,
  ...props
}: SidebarLinkProps) => (
  <NavLink
    aria-label={`Go to ${title}`}
    className={({ isActive }) =>
      clsx(
        "flex items-center space-x-1 rounded-lg px-1 py-0.5 text-sm font-medium transition",
        "hover:bg-neutral-200 hover:bg-opacity-[.6]",
        className,
        {
          "bg-neutral-200 bg-opacity-[.6] font-semibold": isActive,
        }
      )
    }
    to={to}
    {...props}
  >
    <span className="inline-flex grow items-center gap-1">{title}</span>
  </NavLink>
);
