import { PageTitle } from "@atlas/components";

import { CustomersTable } from "./components";

export const CustomersPage = () => {
  return (
    <main className="flex flex-col gap-3 p-3">
      <PageTitle title="Customers" />
      <CustomersTable />
    </main>
  );
};
