import { simplePagination } from "@m/api";

export const cacheConfig = {
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        awsAccounts: simplePagination(),
        companies: simplePagination(),
        marketplaceMeteringTransactions: simplePagination(),
      },
    },
  },
};
