import clsx from "clsx";

import { Badge, Suspensed } from "@m/ui";

import { DATA_MISSING_PLACEHOLDER } from "../constants";

export const Field = ({
  label,
  value = DATA_MISSING_PLACEHOLDER,
  loading,
}: {
  label: string;
  value: Array<string> | string | number;
  loading: boolean;
}) => {
  const dataMissing = value === DATA_MISSING_PLACEHOLDER;
  return (
    <div className="ml-1 flex justify-between gap-x-4 text-sm font-semibold text-default">
      {label}
      <div
        className={clsx("flex flex-wrap justify-end text-right font-normal", {
          "text-gray-400": dataMissing,
        })}
      >
        <Suspensed loading={loading} height="20px" width="96px">
          {value instanceof Array ? (
            <div aria-label={label}>
              {value.toSorted().map((item, i) => {
                return (
                  <Badge
                    key={`customer-detail-map-${i}`}
                    size="small"
                    className="ml-0.5"
                    label={item}
                  />
                );
              })}
            </div>
          ) : (
            <div aria-label={label}>{value}</div>
          )}
        </Suspensed>
      </div>
    </div>
  );
};
